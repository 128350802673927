<script>
import appConfig from "../../../app.config";
import NavMenu from "../landing/inc/nav-menu.vue";
import Footer from "../landing/inc/footer.vue";

import Img1 from "@/assets/images/mes/mes-capture-dashboard.png";
import Img2 from "@/assets/images/mes/mes-capture-order.png";
import Img3 from "@/assets/images/mes/mes-capture-imposition.png";
import Img4 from "@/assets/images/mes/mes-capture-workorder.png";
import Img5 from "@/assets/images/mes/mes-capture-workorderview.png";

export default {
    page: {
        title: "인쇄쇼핑몰",
        meta: [{
            name: "printshop",
            content: appConfig.description
        }],
    },
    data() {
        return {
            title: "Starter",
            Img1, Img2, Img3, Img4, Img5,
            items: [{
                text: "Pages",
                href: "/",
            },
            {
                text: "Starter",
                active: true,
            },
            ],
        };
    },
    components: {
        NavMenu,
        Footer
    },
};
</script>

<template>
    <div class="layout-wrapper">

        <nav-menu></nav-menu>

        <div class="bg-overlay bg-overlay-pattern"></div>

        <section class="section header">
            <div class="bg-overlay"></div>
        </section>

        <section class="section pb-0 hero-section" id="hero">
            <div class="bg-overlay bg-overlay-pattern"></div>
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8" sm="10">
                        <div class="text-center mt-lg-5 pt-5">
                            <h1 class="display-6 fw-semibold mb-3 lh-base">인쇄 생산공정관리 솔류션 <span class="text-success">다온MES</span>
                            </h1>
                            <p class="lead text-muted lh-base">생산관리, 작업지시서, 업무배정, 장비관리, 외주관리, 자재관리 등</p>
                            <p class="lead text-muted lh-base">인쇄 생산공정에서의 최적화된 기능을 제공합니다.</p>

                            <div class="d-flex gap-2 justify-content-center mt-4">
                                <router-link to="/pages/contactus" class="btn btn-primary" v-if="false">데모 요청 <i class="ri-arrow-right-line align-middle ms-1"></i></router-link>
                                <router-link to="/pages/contactus" class="btn btn-danger">제작 문의<i class="ri-eye-line align-middle ms-1"></i></router-link>
                            </div>
                            
                        </div>

                        <div class="mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel">
                            <div class="demo-img-patten-top d-none d-sm-block">
                                <img src="@/assets/images/landing/img-pattern.png" class="d-block img-fluid" alt="...">
                            </div>
                            <div class="demo-img-patten-bottom d-none d-sm-block">
                                <img src="@/assets/images/landing/img-pattern.png" class="d-block img-fluid" alt="...">
                            </div>

                            <b-carousel :interval="2000" class="carousel slide carousel-fade"  v-on:click="alert('g')">
                                <div class="carousel-inner shadow-lg p-2 bg-white rounded">
                                    <b-carousel-slide active :img-src="Img1"/>
                                    <b-carousel-slide :img-src="Img2" />
                                    <b-carousel-slide :img-src="Img3" />
                                    <b-carousel-slide :img-src="Img4" />
                                    <b-carousel-slide :img-src="Img5" />
                                </div>
                            </b-carousel>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
            <div class="position-absolute start-0 end-0 bottom-0 hero-shape-svg">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1440 120">
                    <g mask="url(&quot;#SvgjsMask1003&quot;)" fill="none">
                        <path d="M 0,118 C 288,98.6 1152,40.4 1440,21L1440 140L0 140z">
                        </path>
                    </g>
                </svg>
            </div>
        </section>
        
        <section class="section bg-light" id="marketplace">
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8">
                        <div class="text-center mb-5">
                            <h2 class="mb-3 fw-semibold lh-base">다온 MES 차별된 기능</h2>
                            <p class="text-muted mb-4">다온 MES 관리자만의 강력한 기능을 살펴보세요.</p>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="4" class="product-item artwork crypto-card 3d-style">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/mes/mes-feature-dashboard.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">전체 생산진행 현황 - 대시보드</router-link>
                                </h5>
                                <p class="text-muted mb-0">전체 생산설비의 진행현황을 한눈에 확인</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item music crypto-card games">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/mes/mes-feature-menutree.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">MES 메뉴트리</router-link>
                                </h5>
                                <p class="text-muted mb-0">인쇄 생산공정 관리의 최적화된 기능 정의</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music games">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/mes/mes-feature-responsive.png" alt=""
                                    class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">반응형 UI - MES</router-link>
                                </h5>
                                <p class="text-muted mb-0">언제 어디서나 다양한 디바이스에서 생산공정관리 가능</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </section>


        <section class="section" id="services" v-if="false">
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8">
                        <div class="text-center mb-5">
                            <h1 class="mb-3 ff-secondary fw-semibold lh-base">최적화된 인쇄공정관리 시스템</h1>
                            <p class="text-muted">인쇄사업에 최적화된 기능을 제공합니다.</p>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="g-3">
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-pencil-ruler-2-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">Creative Design</h5>
                                <p class="text-muted my-3 ff-secondary">The creative design includes designs that are unique, effective and memorable.</p>
                                <div>
                                    <b-link href="#" class="fs-13 fw-medium">Learn More <i
                                            class="ri-arrow-right-s-line align-bottom"></i></b-link>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-palette-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">Unlimited Colors</h5>
                                <p class="text-muted my-3 ff-secondary">The collection of rules and guidelines which designers use to communicate with users through appealing.</p>
                                <div>
                                    <b-link href="#" class="fs-13 fw-medium">Learn More <i
                                            class="ri-arrow-right-s-line align-bottom"></i></b-link>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-lightbulb-flash-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">Strategy Solutions</h5>
                                <p class="text-muted my-3 ff-secondary">Business development firm that provides strategic planning, market research services and project.</p>
                                <div>
                                    <b-link href="#" class="fs-13 fw-medium">Learn More <i
                                            class="ri-arrow-right-s-line align-bottom"></i></b-link>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-customer-service-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">Awesome Support</h5>
                                <p class="text-muted my-3 ff-secondary">Awesome Support is the most versatile and feature-rich support plugin for all version.</p>
                                <div>
                                    <b-link href="#" class="fs-13 fw-medium">Learn More <i
                                            class="ri-arrow-right-s-line align-bottom"></i></b-link>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-stack-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">Truly Multipurpose</h5>
                                <p class="text-muted my-3 ff-secondary">You usually get a broad range of options to play with. This enables you to use a single theme across multiple.</p>
                                <div>
                                    <b-link href="#" class="fs-13 fw-medium">Learn More <i
                                            class="ri-arrow-right-s-line align-bottom"></i></b-link>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-settings-2-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">Easy to customize</h5>
                                <p class="text-muted my-3 ff-secondary">Personalise your own website, no matter what theme and what customization options.</p>
                                <div>
                                    <b-link href="#" class="fs-13 fw-medium">Learn More <i
                                            class="ri-arrow-right-s-line align-bottom"></i></b-link>
                                </div>
                            </div>
                        </div>
                    </b-col>

                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-slideshow-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">Responsive & Clean Design</h5>
                                <p class="text-muted my-3 ff-secondary">Responsive design is a graphic user interface (GUI) design approach used to create content.</p>
                                <div>
                                    <b-link href="#" class="fs-13 fw-medium">Learn More <i
                                            class="ri-arrow-right-s-line align-bottom"></i></b-link>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-google-fill fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">Google Font Collection</h5>
                                <p class="text-muted my-3 ff-secondary">Google Fonts is a collection of 915 fonts, all available to use for free on your website.</p>
                                <div>
                                    <b-link href="#" class="fs-13 fw-medium">Learn More <i
                                            class="ri-arrow-right-s-line align-bottom"></i></b-link>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-briefcase-5-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">Top Industry Specialists</h5>
                                <p class="text-muted my-3 ff-secondary">An industrial specialist works with industrial operations to ensure that manufacturing facilities work.</p>
                                <div>
                                    <b-link href="#" class="fs-13 fw-medium">Learn More <i
                                            class="ri-arrow-right-s-line align-bottom"></i></b-link>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <Footer></Footer>
    </div>
</template>